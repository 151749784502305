import React from 'react';
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles';
import {
    createMuiTheme,
    responsiveFontSizes,
    MuiThemeProvider,
    Typography
} from '@material-ui/core';

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);
const title = "MIRA TODO EN ALTA DEFINICION";
const subtitle = "Con nuestros planes de internet vas a poder disfrutar de tus contenidos favoritos en la más alta calidad las veces que quieras.";

const useStyles = makeStyles((theme) => ({
    divGeneral: {
        width: "80%",
        display: "flex",
        flexFlow: "row wrap",
        marginTop: "2em",
        marginLeft: "auto",
        marginRight: "auto",
        
    },
    divContainer: {
        width: "100%",
        display: "flex",
        flexFlow: "row wrap",
        justifyContent: "center",
        
        
    },
    divContainerTexto: {
        width: "49%",
        display: "flex",
        flexFlow: "column wrap",
        justifyContent: "center",
        [theme.breakpoints.between("0", "700")]: {
            width: "100%",
            
        },
    },
    divContainerImg: {
        width: "51%",
        display: "flex",
        marginLeft: "auto",
        marginRight: "auto",
        [theme.breakpoints.between("0", "1075")]: {
            width: "52%",
            display: "flex",
            justifyContent: "center",
            alignSelf: "center",
        },
    },
    Img: {
        display: "flex",
        marginLeft: "auto",
        minWidth: "300px",
        maxWidth: "90%",
        
        // display: "flex",
        // alignSelf: "center",
        [theme.breakpoints.between("0", "490")]: {
            width: "400px",
            marginLeft: "auto",
            marginRight: "auto",
        },
        [theme.breakpoints.between("491", "900")]: {
            width: "600px",
        },
        [theme.breakpoints.between("491", "900")]: {
            width: "600px",
        },
        [theme.breakpoints.between("900", "1300")]: {
            width: "1000px",
        },
    },
    
    title: {
        color: "#121132",
        fontWeight: "bold",
        
    },

    subtitle: {
        color: "#121132",
        fontWeight: "light",
        letterSpacing: 2,
    },

    linea: {
    
        borderBottom: "2px solid #3DEBE8",
        alignSelf: "center",
        marginTop: "0.5em",
        marginBottom: "0.5em",
        // marginBottom: "1em",
        // marginLeft: "auto",
        // marginRight: "auto",
        [theme.breakpoints.between("0", "549")]: {
            width:"80%",
        borderBottom: "2px solid #3DEBE8",
        alignSelf: "center",
        // marginTop: "0.5em",
        // marginBottom: "0.5em",
        // marginLeft: "auto",
        // marginRight: "auto",
        },
    
        [theme.breakpoints.between("550", "763")]: {
            width:"250px",
        borderBottom: "2px solid #3DEBE8",
        alignSelf: "center",
        // marginTop: "0.5em",
        // marginBottom: "0.5em",
        // marginLeft: "auto",
        // marginRight: "auto",
        },
        [theme.breakpoints.between("764", "960")]: {
            width:"300px",
        borderBottom: "2px solid #3DEBE8",
        alignSelf: "center",
        },

        [theme.breakpoints.between("961", "1016")]: {
            width:"200px",
        borderBottom: "2px solid #3DEBE8",
        alignSelf: "center",
        },
        [theme.breakpoints.up(1016)]: {
            width:"450px",
        borderBottom: "2px solid #3DEBE8",
        alignSelf: "center",
        },
    },
}));

const Section = () => {
    const classes = useStyles();
    return (
        <div className={classes.divGeneral}>
            <div className={classes.divContainer}>
                <div className={classes.divContainerTexto}>
                 
                    <MuiThemeProvider theme={theme}>
                        <Typography variant="h4" gutterBotton className={classes.title} align="center">{title} </Typography>
                
                        <Typography variant="h4" gutterBotton className={classes.linea} align="center"> </Typography>

                        <Typography variant="subtitle1" gutterBotton className={classes.subtitle} align="center">{subtitle}</Typography>

                    </MuiThemeProvider>
                   
                    {/* <Box 
                        textAlign="center" 
                        fontWeight="fontWeightBold" 
                        fontSize="h4.fontSize"  
                        letterSpacing={2} 
                        className={classes.title}
                        >
                            MIRÁ TODO EN ALTA DEFINICIÓN HD
                        
                    </Box>

                    <div className={classes.linea}></div>

                    <Box 
                        textAlign="center" 
                        fontWeight="fontWeightLight" 
                        fontSize={16}
                        marginTop="1em"
                        className={classes.subtitle}
                        >
                            Con nuestros planes de internet vas a poder disfrutar de los mejores contenidos, en la más alta calidad de imagen y sonido.
                    </Box> */}

                </div>
                <div className={classes.divContainerImg}>
                    <img className={classes.Img} src="https://i.ibb.co/HFzZxJ3/tv-1.png" alt="Con Next mirá todo en alta definición"/>
                </div>
                
            </div>
        </div>
    );
};

export default Section;