import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

//-------------------------------------------------//
import {
  createMuiTheme,
  responsiveFontSizes,
  MuiThemeProvider,
  Typography
} from '@material-ui/core';

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const title1 = "Disfruta"



const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const tutorialSteps = [
  {
    title: 'Disfruta',
    subtitle: '',
    imgPath:
      'https://i.ibb.co/hHsJWrh/Rectangle-5-copy-3-1.jpg',
  },
  {
    title: 'Videollamadas',
    subtitle: '',
    imgPath:
      'https://i.ibb.co/qj1vQhc/Layer-sad7.jpg',
  },
  {
      title: 'Juegos online',
    subtitle: '',
    imgPath:
      'https://i.ibb.co/QHcpSjq/Layer-sssssssdddd2.jpg',
  },
 
];

const useStyles = makeStyles((theme) => ({
  root: {
   
    maxWidth: '100%',
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.between("0", "550")]: {
      display: "none",
  },
    
  },

  header: {
    display: 'flex',
    flexFlow: "column wrap",
    textAlign: 'center',
    paddingLeft: theme.spacing(4),
    backgroundColor: 'transparent',
    zIndex: 100,
    position: 'absolute',
    color: 'white',
    [theme.breakpoints.between("0", "550")]: {
      display: "none",
  },
  },

  containerResponsive: {
    
  },

headerResponsive: {
  display: 'flex',
    flexFlow: "column wrap",
    textAlign: 'center',
    backgroundColor: 'transparent',
    zIndex: 100,
    position: 'relative',
    color: 'white',
    [theme.breakpoints.between("0", "381")]: {
      display: "flex",
      
      

      
      
  },
    
},

  imagenResponsive: {
    display: "none",
    [theme.breakpoints.between("0", "550")]: {
      display: "flex",
      width: "100%",
      height: "100vh",
  },
  },
  img: {
    height: "700px",
    display: 'flex',
    width: '100%',
    [theme.breakpoints.between("0", "550")]: {
      display: "none",
  },
  //    [theme.breakpoints.between("0","720")]: {
     
  //  }, 

  },
  divtexto: {
    display: "flex",
    flexFlow: "column wrap",
    width: "100%",
    justifyContent: "flex-end",
    alignItems: "center",
    // [theme.breakpoints.between("0","590")]: {
    //   width: "600px",

    //   },
    [theme.breakpoints.between("0", "550")]: {
      display: "none",
  },
     },

  title: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    fontWeight: "bold",
    [theme.breakpoints.between("0", "550")]: {
      display: "none",
  },
  },
  subtitle: {
    width: "70%",
    marginTop: "1em",
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.between("0", "550")]: {
      display: "none",
  },
  },
  button: {
borderRadius: "1em",
backgroundColor: "blue",
color: "white",
backgroundColor: "#121132",
width: "10em",
marginTop: "3em",
paddingTop: "0.6em",
paddingBottom: "0.6em",
'&:hover': { 
  background: 'rgba(39, 36, 106, 1)',
  borderButtom: "5px solid red"
},
[theme.breakpoints.between("0", "550")]: {
  display: "none",
},
  },
  
//------------------------RESPONSIVE----------------------------------//
title1: {
  width: "90%",
  marginTop: "11em",
  fontWeight: "bold",
  marginLeft: "1em",
  zIndex: 100,
  position: "absolute",
  [theme.breakpoints.up(550)]: {
    display: "none",
},
[theme.breakpoints.between("0", "350")]: {
  width: "80%",

},
[theme.breakpoints.down(271)]: {
  width: "80%",
 
},

},
subtitle1: {
  width: "95%",
  marginTop: "20em",
  marginLeft: "1em",
  marginRight: "auto",
  zIndex: 99,
  position: "absolute",
  [theme.breakpoints.up(550)]: {
    display: "none",
},
[theme.breakpoints.between("0", "350")]: {
  width: "80%",

},
[theme.breakpoints.down(271)]: {
  width: "80%",
 
},
  
},
button1: {
borderRadius: "1em",
backgroundColor: "blue",
color: "white",
backgroundColor: "#121132",
width: "10em",

paddingTop: "0.6em",
paddingBottom: "0.6em",

//---------------------------//

width: "40%",
marginTop: "34em",
marginLeft: "12em",
marginRight: "auto",
zIndex: 99,
position: "absolute",
'&:hover': { 
background: 'rgba(39, 36, 106, 1)',
borderButtom: "5px solid red",
},
[theme.breakpoints.between("350", "395")]: {
  width: "45%",
  marginTop: "34em",
marginLeft: "8em",
marginRight: "auto",

},
[theme.breakpoints.between("395", "490")]: {
  width: "45%",
  marginTop: "34em",
marginLeft: "9em",
marginRight: "auto",

},
[theme.breakpoints.between("0", "350")]: {
  width: "45%",
  marginTop: "34em",
  marginLeft: "5.8em",
marginRight: "auto",
},
[theme.breakpoints.down(271)]: {
  width: "55%",
  marginTop: "34em",
  marginLeft: "5em",
marginRight: "auto",
},
[theme.breakpoints.up(550)]: {
  display: "none",
},
},

puntoInvisible: {
  width: "80%",
  marginTop: "38em",
  marginLeft: "2em",
  marginRight: "auto",
  zIndex: 99,
  position: "absolute",
  

},

}));

function Carousel() {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = tutorialSteps.length;


  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <div className={classes.master} id="/inicio">
      <div className={classes.containerResponsive}> 
      <div className={classes.headerResponsive}>
      <img className={classes.imagenResponsive} src="https://i.ibb.co/6mNZSGk/450x850.jpg" alt="Next Internet"/>
      <MuiThemeProvider theme={theme}>
        <Typography variant="h3" gutterBotton className={classes.title1} align="center">{title1} </Typography>
        {/* <Typography  variant="subtitle1"  gutterBotton className={classes.subtitle1} align="center">{subtitle1}</Typography> */}
        <Button className={classes.button1} variant="contained" href="/planes"> Leer Mas...</Button>
        <Typography  variant="subtitle1"  gutterBotton className={classes.puntoInvisible} id="/planes" align="center"></Typography>
        </MuiThemeProvider>
        </div>
      </div>
    
    <div className={classes.root} id="/home">
      <div className={classes.header}>
        <div className={classes.divtexto}>
        <MuiThemeProvider theme={theme}>
        <Typography variant="h3" gutterBotton className={classes.title} align="center">{tutorialSteps[activeStep].title} </Typography>
        <Typography  variant="subtitle1"  gutterBotton className={classes.subtitle} align="center">{tutorialSteps[activeStep].subtitle}</Typography>

          {/* <Box className={classes.title} fontSize="h2.fontSize" fontWeight="fontWeightBold">{tutorialSteps[activeStep].title}</Box>
        <Box className={classes.subtitle} fontSize="h5.fontSize" fontWeight="fontWeightLight">{tutorialSteps[activeStep].subtitle}</Box> */}
        <Button className={classes.button} variant="contained" href="#/planes"> Leer Mas...</Button>
        </MuiThemeProvider>
        </div>
      </div>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        interval={4000}
      >
        {tutorialSteps.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 4 ? (
              <img  className={classes.img} src={step.imgPath} alt="Disfrutando de la maxima velocidad de Next Internet viendo Netflix y Youtube" />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
     
    </div>
    </div>
  );
}






export default Carousel;
