import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
//----------------------------------------------------------------//
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MailIcon from '@material-ui/icons/Mail';
import HomeIcon from '@material-ui/icons/Home';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import WorkIcon from '@material-ui/icons/Work';
import BusinessIcon from '@material-ui/icons/Business';

//----------------------------------------------//
import '../navbar.css';
//-----------------------------------------------------------//
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";





const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: drawerWidth,
    },
    
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-start',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginRight: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    },
    links: {
      textDecoration: "none",
      color: "#000000"
    },
    

    //CODIGO ANTERIOR
    logoNext: {
        height: 53,
        width: 140 ,
        marginLeft: "10em",
        marginTop: "2em",
        marginBottom : "1em",
        [theme.breakpoints.between("297", "1251")]: {
          height: 53,
        width: 140 ,
        marginLeft: "1em",
        marginTop: "2em",
        marginBottom : "1em",
      },
      [theme.breakpoints.between("0", "296")]: {
        height: 53,
      width: 140 ,
      marginLeft: "1em",
      marginTop: "2em",
      marginBottom : "1em",
    },
    },

    menu: {
        marginRight: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      }
    },
      menuBurger: {
          color: "white",
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
          display: 'none',
        },
      },
      menuButton: {
        marginRight: theme.spacing(2),
        borderRadius: 10,
        marginTop: "2em",
        marginBottom : "1em",
        color: "white",
        '&:hover': {
          background: "#006C6C",
        },
      },
      menuContacto: {
        marginRight: theme.spacing(2),
        borderRadius: 10,
        marginTop: "2em",
        marginBottom : "1em",
        marginRight: "6.5em",
        color: "white",
        transition: "heigth 2s",
        '&:hover': {
          background: "#006C6C",
        },
        [theme.breakpoints.between("0", "1251")]: {
          marginRight: theme.spacing(2),
        borderRadius: 10,
        marginTop: "2em",
        marginBottom : "1em",
        marginRight: "0.5em",
        color: "white",
      },
      },
  }));

const Header = () => {
    const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
const [navbar,setNavbar] = useState(false);



  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  //navbar
  const changeBackground = () => {
    if (window.scrollY>= 600)
    setNavbar(true);
    
   else {
    setNavbar(false);
  };
    // console.log(window.scrollY);
  };
  window.addEventListener('scroll', changeBackground);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        elevation={0}
        
        className={navbar ? 'navbar active' : 'navbar' }
      >
          <Link to="/" exact><img src="https://i.ibb.co/q9hZYYN/logochico.png" alt="Logo de Next Internet" className={classes.logoNext}/></Link>
        <Toolbar>
          
            
        <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            className={classes.menu}
             >
            
              <Button className={classes.menuButton} 
              color="inherit" 
              href="#/home">
                Inicio
              </Button>

              <Button className={classes.menuButton} color="inherit" href="#/planes">
                  Planes
              </Button>
            
            <Button className={classes.menuButton} color="inherit" href="#/empresa" >Empresa</Button>

            <Button className={classes.menuButton} color="inherit" href="#/servicios" >Servicios</Button>

            <Button className={classes.menuContacto}  color="inherit" href="#/contacto">Contacto</Button>
         
            </Grid>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            className={clsx(open && classes.hide)}
          >
            <MenuIcon className={classes.menuBurger}/>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        
        <Divider />
        <a href="#/inicio" className={classes.links}>
            <List >
              <ListItem button>
                <ListItemIcon>
                    <HomeIcon/>
                </ListItemIcon>
                <ListItemText primary={"Inicio"}/>
              </ListItem>
            </List>
            </a>
            <a href="#/planes" className={classes.links}>
            <List >
              <ListItem button>
                <ListItemIcon>
                    <LibraryBooksIcon/>
                </ListItemIcon>
                <ListItemText primary={"Planes"}/>
              </ListItem>
            </List>
            </a>
            
            <a href="#/empresa" className={classes.links}>
            <List >
              <ListItem button>
                <ListItemIcon>
                <BusinessIcon />
                </ListItemIcon>
                <ListItemText primary={"Empresa"}/>
              </ListItem>
            </List>
            </a>
            
            <a href="#/servicios" className={classes.links}>
            <List >
              <ListItem button>
                <ListItemIcon>
                <WorkIcon />
                </ListItemIcon>
                <ListItemText primary={"Servicios"}/>
              </ListItem>
            </List>
            </a>
            

            <a href="#/contacto" className={classes.links}>
            <List >
              <ListItem button>
                <ListItemIcon>
                <MailIcon />
                </ListItemIcon>
                <ListItemText primary={"Contacto"}/>
              </ListItem>
            </List>
            </a>
      </Drawer>
    </div>
  );
};

export default Header;