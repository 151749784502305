import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
//Portal Material UI
import Portal from '@material-ui/core/Portal';
//REACT ROUTER
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    containerSectionPlanes: {
        width: '100%',
        // height: '100%',
        display: "flex",
        flexFlow: "row wrap",
        
        [theme.breakpoints.between("0","333")]: {
            display: "flex",
            flexFlow: "column nowrap",
            // height: "1760px",
            //prueba
            alignItems: "center",
             justifyContent: "center",
             alignContent: "center",
          },
        [theme.breakpoints.between("333","828")]: {
            display: "flex",
            flexFlow: "column nowrap",

            // height: "1703px",
            //prueba
            alignItems: "center",
             justifyContent: "center",
             alignContent: "center",
          },
          [theme.breakpoints.between("829", "1149")]: {
              display: "flex",
              flexFlow: "column wrap",
            // height: "1731px",
             alignItems: "center",
             justifyContent: "center",
             alignContent: "center",
             width: "100%",
          },
          [theme.breakpoints.between("1131", "1134")]: {
            display: "flex",
            flexFlow: "column wrap",
          height: "auto",
           alignItems: "center",
           justifyContent: "center",
           alignContent: "center",
           width: "100%",
        },

          [theme.breakpoints.between("1149", "1151")]: {
            display: "flex",
            flexFlow: "column wrap",
        //   height: "1211px",
            
           alignItems: "center",
           justifyContent: "center",
           alignContent: "center",
           width: "100%",
        },
        
          [theme.breakpoints.between("1151", "1233")]: {
            display: "flex",
            flexFlow: "column wrap",
            // height: "1703px",
            
            alignItems: "center",
            justifyContent: "center",
            alignContent: "center",
            width: "100%",
        },
        
        [theme.breakpoints.between("1232", "1233")]: {
            display: "flex",
            flexFlow: "column wrap",
            // height: "1231px",
            paddingBotton: "10px",
           alignItems: "center",
           justifyContent: "center",
           alignContent: "center",
           width: "100%",
        },
          [theme.breakpoints.up(1233)]: {
            display: "flex",
            flexFlow: "column nowrap",
        //   height: "718px",
        },
    },
    title: {
        width: "100%",
        color: "#121132",
        marginBottom: "0.3em",
        marginTop: "0.5em",
        [theme.breakpoints.between("0", "334")]: {
            marginBottom: "5px",
            marginTop: "1em",
            fontSize: "3em",
        },
    },
        
    
    subtitle: {
        width: "100%",
        marginBottom: "-20px",
        padding: 0,
        color: "#121132",
        
        [theme.breakpoints.between("0", "549")]: {
            fontSize: "2.5em",
            marginBottom: "1px",
            
        },
        [theme.breakpoints.between("549", "763")]: {
            fontSize: "2.8em",
            marginBottom: "-10px",
        },
    },

    subsubtitle:{
        
        padding: 0,
        color: "#121132",
        [theme.breakpoints.between("0", "549")]: {
            fontSize: "1em",
            marginTop: "10 px",
        },
        [theme.breakpoints.between("549", "763")]: {
            marginTop: "10 px",
            fontSize: "1.5em",
        },
        
    },
    parrafo: {
        [theme.breakpoints.between("0", "549")]: {
            fontSize: "0.9em",
        },
        [theme.breakpoints.between("549", "763")]: {
            fontSize: "1em",
        },
    },
    linea: {
        width:"230px",
        borderBottom: "2px solid #3DEBE8",
        marginBottom: "1em",
        marginLeft: "auto",
        marginRight: "auto",

        [theme.breakpoints.between("0", "549")]: {
            width:"70%",
        borderBottom: "2px solid #3DEBE8",
        marginTop: "0.5em",
        marginBottom: "0.5em",
        marginLeft: "auto",
        marginRight: "auto",
        },

        [theme.breakpoints.between("549", "763")]: {
            width:"80%",
        borderBottom: "2px solid #3DEBE8",
        marginTop: "0.5em",
        marginBottom: "0.5em",
        marginLeft: "auto",
        marginRight: "auto",
        },
    },

    containerButtonsPlanes: {
        width: "100%",
        display: "flex",
        flexFlow: "row wrap",
        justifyContent: "center",
        alignItems: "center", 
    },

    buttonBase: {
        display: "flex",
        flexFlow: "row wrap",
        width: "100%",
        marginBottom: "3em",
        display: "flex",
        justifyContent: "center",

        [theme.breakpoints.between("0","334")]: {
            display: "flex",
        flexFlow: "column wrap",
        width: "70%",
        marginBottom: "2em",
        display: "flex",
        justifyContent: "center",
          },
            },

    ButtonsPlanes: {
        display: "flex",
        flexFlow: "row wrap",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: "2%",
        color: "#121132",
        position: 'relative',
        overflow: 'hidden',
        display: 'inline-block',
        textDecoration: 'none',
        '&:hover': { 
            borderBottom: "1px solid #121132"
        },
        [theme.breakpoints.between("0", "334")]: {
            marginBottom: "5px",
        },
    },

    containerPlanes: {
        display: "flex",
        flexFlow: "row wrap",
        justifyContent: "center",
        alignItems: "center",
        width: "25em",
        height: "35em",
        marginRight: "2em",
        background: "white",
        borderRadius: "25px",
        background: '#F5F7FB',
        marginBottom: "30px",
        '&:hover': { 
            background: '#CAE6F2',
        },
        
        
        
    },
    buttonContratar: {
        width: "50%",
        borderRadius: "0.7em",
        display: "flex",
        justifyContent: "center",
        marginTop:"1em",
        marginBottom:"1em",
        marginLeft: "auto",
        marginRight: "auto",
        backgroundColor: "#121132",
        
    },
    containerTotal: {
        display: "flex",
        flexFlow: "column wrap",
        justifyContent: "center",
        
    },
    
    pruebadivplanes: {
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "center",
    alignItems: "center",
    
    [theme.breakpoints.between("0", "829")]: {
        display: "flex",
        flexFlow: "row wrap",
        justifyContent: "center",
        alignItems: "center",
        width: "80%",
    },
    [theme.breakpoints.between("829", "1235")]: {
        display: "flex",
        flexFlow: "column wrap",
        justifyContent: "center",
        alignItems: "center",
        width: "50%",
    },

    [theme.breakpoints.between("1149", "1151")]: {
        display: "flex",
        flexFlow: "row wrap",
        justifyContent: "center",
        alignItems: "center",
        width: "50%",
    },
    },
    pruebaimg1:{
        height: "170px",
        width: "250px",
        [theme.breakpoints.between("0", "763")]: {
            height: "35%",
            width: "72%",
        },
    },

    }));

const Planes = () => {
    const classes = useStyles();

    const [showHogar, setShowHogar] = useState(true);


    //FIN PRUEBA -------------------------------------------------------------------------------------------------------------------//
    const [showPymes, setShowPymes] = useState(false);
    const [showEmpresa, setShowEmpresa] = useState(false);
    const container = React.useRef(null);
  
    const handleClickHogar = () => {
        setShowHogar(<div className={classes.pruebadivplanes}>
            {/* comienzo div prueba */}
            <div className={classes.containerPlanes}>
                        
                        <img className={classes.pruebaimg1} src="https://i.ibb.co/b6NFzXV/logo-easdmpres-Asset-15.png" alt="Plan Hogar" border="0" />
                       
                        <Typography color="initial">
                            <div className={classes.containerTotal}>
                            <Box 
                            textAlign="center"
                             fontWeight="fontWeightBold"
                              fontSize="h2.fontSize" 
                              className={classes.subtitle}> 
                                    6 MB</Box>

                            <Box 
                            textAlign="center" 
                            fontWeight="fontWeightLight" 
                            fontSize="h3.fontSize"
                            className={classes.subsubtitle}
                            letterSpacing={6}>
                                Hogar</Box>

                            <div className={classes.linea}></div>

                            <Box 
                            className={classes.parrafo}
                            textAlign="center" 
                            fontWeight="fontWeightLight"> 
                            Velocidad de descarga 6MB.</Box>

                            <Box 
                            className={classes.parrafo}
                            textAlign="center" 
                            fontWeight="fontWeightLight" > 
                            Velocidad de carga hasta 800Kb.
                            </Box>

                            <Box 
                            className={classes.parrafo}
                            textAlign="center" 
                            fontWeight="fontWeightLight"> 
                            Servicio técnico garantizado 24/48Hs.</Box>
                            <Link  to="/Contacto"><Button style={{ color: 'inherit', textDecoration: 'inherit'}} className={classes.buttonContratar} variant="contained" size="large" color="primary">Contratar</Button></Link></div>
                            </Typography>
                        </div>
                        <div className={classes.containerPlanes}>
                         
                     <img className={classes.pruebaimg1} src="https://i.ibb.co/brZ0MzC/logo-easdmpres-Asset-16.png" alt="Planes Hogar+" border="0"/>
                     <Typography color="initial">
                     <div className={classes.containerTotal}> 

                            <Box 
                            className={classes.subtitle}
                             textAlign="center"
                              fontWeight="fontWeightBold"
                               fontSize="h2.fontSize">
                                8 MB  </Box>

                            <Box 
                            className={classes.subsubtitle}
                             textAlign="center" 
                             fontWeight="fontWeightLight" 
                             fontSize="h3.fontSize"
                             letterSpacing={6}>
                                 Hogar+</Box>

                                 <div className={classes.linea}></div>

                            <Box 
                            className={classes.parrafo}
                            textAlign="center"
                             fontWeight="fontWeightLight"> 
                            Velocidad de descarga 8MB.</Box>

                            <Box 
                            className={classes.parrafo}
                            textAlign="center" 
                            fontWeight="fontWeightLight"> 
                            Velocidad de carga hasta 800Kb.</Box>

                            <Box 
                            className={classes.parrafo}
                            textAlign="center"
                             fontWeight="fontWeightLight"> 
                            Servicio técnico garantizado 24/48Hs.</Box>
                            <Link to="/Contacto" style={{ color: 'inherit', textDecoration: 'inherit'}}>
                                <Button className={classes.buttonContratar} variant="contained" size="large" color="primary">Contratar</Button>
                            </Link>
                           
                        </div>
                            </Typography>
                        </div>
                        <div className={classes.containerPlanes}>
                    <img className={classes.pruebaimg1} src="https://i.ibb.co/vLJL7MM/logo-easdmpres-Asset-17.png" alt="Planes Premium" border="0" width="150"/>
                    <Typography color="initial">
                    <div className={classes.containerTotal}>
                        <div className="MuiIcon-fontSizeLarge"></div>
                            <Box 
                            className={classes.subtitle} 
                            textAlign="center" 
                            fontWeight="fontWeightBold" 
                            fontSize="h2.fontSize">
                                 10 MB</Box>

                            <Box 
                            className={classes.subsubtitle}
                             textAlign="center" 
                             fontWeight="fontWeightLight"
                              fontSize="h3.fontSize"
                              letterSpacing={6}>
                                  Premium</Box>

                            <div className={classes.linea}></div>

                            <Box 
                            className={classes.parrafo}
                            textAlign="center" 
                            fontWeight="fontWeightLight"> 
                            Velocidad de descarga 10MB.</Box>

                            <Box 
                            className={classes.parrafo}
                            textAlign="center" 
                            fontWeight="fontWeightLight"> 
                            Velocidad de carga hasta 1MB</Box>

                            <Box
                            className={classes.parrafo} 
                            textAlign="center"
                            fontWeight="fontWeightLight"> 
                            Servicio técnico garantizado 24/48Hs.</Box>
<Link to="/ContratarPlanes" style={{ color: 'inherit', textDecoration: 'inherit'}}> 
<Button className={classes.buttonContratar} variant="contained" size="large" color="primary">Contratar</Button>
</Link>
                            
                            </div>
                            </Typography>
                        </div>
       
        </div>);
        setShowPymes("");
        setShowEmpresa("");
        
    };
    
    const handleClickPymes = () => {
        setShowPymes( <div className={classes.containerPlanes}>
                             
            <img src="https://i.ibb.co/pQWdhzv/logo-empres-Asset-16.png" alt="Plan Pymes" border="0" width="150"/>
            <Typography color="initial">
            <div className={classes.containerTotal}  > 

                   <Box 
                   className={classes.subtitle}
                    textAlign="center"
                     fontWeight="fontWeightBold"
                      fontSize="h2.fontSize">
                       + MB  </Box>

                   <Box 
                   className={classes.subsubtitle}
                    textAlign="center" 
                    fontWeight="fontWeightLight" 
                    fontSize="h3.fontSize"
                    letterSpacing={6}>
                        Pymes</Box>

                        <div className={classes.linea}></div>

                   <Box 
                   className={classes.parrafo}
                   textAlign="center"
                    fontWeight="fontWeightLight"> 
                   Planes simetricos.</Box>

                   <Box 
                   className={classes.parrafo}
                   textAlign="center" 
                   fontWeight="fontWeightLight"> 
                   Planes personalizados.</Box>

                   <Box 
                   className={classes.parrafo}
                   textAlign="center"
                    fontWeight="fontWeightLight"> 
                   Servicio técnico garantizado 24/48Hs.</Box>

                  <Button className={classes.buttonContratar} variant="contained" size="large" color="primary">Contratar</Button>
               </div>
                   </Typography>
               </div>);
        setShowHogar ("");
        setShowEmpresa("");
      };
      const handleClickEmpresa = () => {
        setShowEmpresa(
            <div className={classes.pruebadivplanes}>
        <div className={classes.containerPlanes}>
            <img className={classes.pruebaimg} src="https://i.ibb.co/jDY268F/empresa.png" alt="Plan Empresa" border="0" width="150"/>
            <Typography color="initial">
            <div className={classes.containerTotal}>
                
                    <Box 
                    className={classes.subtitle} 
                    textAlign="center" 
                    fontWeight="fontWeightBold" 
                    fontSize="h2.fontSize">
                         + MB</Box>

                    <Box 
                    className={classes.subsubtitle}
                     textAlign="center" 
                     fontWeight="fontWeightLight"
                      fontSize="h3.fontSize"
                      letterSpacing={6}>
                          Empresas</Box>

                    <div className={classes.linea}></div>

                    <Box 
                    className={classes.parrafo}
                    textAlign="center" 
                    fontWeight="fontWeightLight"> 
                    Planes simetricos.</Box>

                    <Box 
                    className={classes.parrafo}
                    textAlign="center" 
                    fontWeight="fontWeightLight"> 
                   Planes personalizados.</Box>

                    <Box 
                    className={classes.parrafo}
                    textAlign="center"
                    fontWeight="fontWeightLight"> 
                    Servicio técnico garantizado 24/48Hs.</Box>
                    <Link to="/Contacto" style={{ color: 'inherit', textDecoration: 'inherit'}}>
                        <Button className={classes.buttonContratar} variant="contained" size="large" color="primary">Contratar</Button>
                    </Link>
                    </div>
                    </Typography>
                    </div>;
                </div>);
        setShowHogar ("");
        setShowPymes("");
      };


    return (
        <div className={classes.containerSectionPlanes} >
            <Box textAlign="center" fontWeight="fontWeightBold" fontSize="h2.fontSize"  letterSpacing={2} className={classes.title} id="/planes">PLANES</Box>
            <div className={classes.containerButtonsPlanes}>
                <div className={classes.buttonBase}>
               
                <Button className={classes.ButtonsPlanes} variant="outlined" size="large" color="transparent" onClick={handleClickHogar}>
                    Hogar
                </Button>
                <Button className={classes.ButtonsPlanes}  variant="outlined" size="large" color="transparent" onClick={handleClickPymes}>
                    Pymes
                </Button>
                <Button className={classes.ButtonsPlanes} variant="outlined" size="large" color="transparent"  onClick={handleClickEmpresa}>
                    Empresas
                </Button>
                
                </div>
                
                
                    {showHogar ? (
                        <div className={classes.pruebadivplanes}>
                        {/* comienzo div prueba */}
                        <div className={classes.containerPlanes}>
                                    
                                    <img className={classes.pruebaimg1} src="https://i.ibb.co/b6NFzXV/logo-easdmpres-Asset-15.png" alt="Plan Hogar" border="0"/>
                                   
                                    <Typography color="initial">
                                        <div className={classes.containerTotal}>
                                        <Box 
                                        textAlign="center"
                                         fontWeight="fontWeightBold"
                                          fontSize="h2.fontSize" 
                                          className={classes.subtitle}> 
                                                6 MB</Box>
        
                                        <Box 
                                        textAlign="center" 
                                        fontWeight="fontWeightLight" 
                                        fontSize="h3.fontSize"
                                        className={classes.subsubtitle}
                                        letterSpacing={6}>
                                            Hogar</Box>
        
                                        <div className={classes.linea}></div>
        
                                        <Box 
                                        className={classes.parrafo}
                                        textAlign="center" 
                                        fontWeight="fontWeightLight"> 
                                        Velocidad de descarga 6MB.</Box>
        
                                        <Box 
                                        className={classes.parrafo}
                                        textAlign="center" 
                                        fontWeight="fontWeightLight" > 
                                        Velocidad de carga hasta 800Kb.
                                        </Box>
        
                                        <Box 
                                        className={classes.parrafo}
                                        textAlign="center" 
                                        fontWeight="fontWeightLight"> 
                                        Servicio técnico garantizado 24/48Hs.</Box>
                                        <Link to="/Contacto" style={{ color: 'inherit', textDecoration: 'inherit'}} ><Button className={classes.buttonContratar} variant="contained" size="large" color="primary">Contratar</Button></Link></div>
                                        </Typography>
                                    </div>
                                    <div className={classes.containerPlanes}>
                                     
                                 <img className={classes.pruebaimg1} src="https://i.ibb.co/brZ0MzC/logo-easdmpres-Asset-16.png" alt="Planes Hogar+" border="0"/>
                                 <Typography color="initial">
                                 <div className={classes.containerTotal}> 
        
                                        <Box 
                                        className={classes.subtitle}
                                         textAlign="center"
                                          fontWeight="fontWeightBold"
                                           fontSize="h2.fontSize">
                                            8 MB  </Box>
        
                                        <Box 
                                        className={classes.subsubtitle}
                                         textAlign="center" 
                                         fontWeight="fontWeightLight" 
                                         fontSize="h3.fontSize"
                                         letterSpacing={6}>
                                             Hogar+</Box>
        
                                             <div className={classes.linea}></div>
        
                                        <Box 
                                        className={classes.parrafo}
                                        textAlign="center"
                                         fontWeight="fontWeightLight"> 
                                        Velocidad de descarga 8MB.</Box>
        
                                        <Box 
                                        className={classes.parrafo}
                                        textAlign="center" 
                                        fontWeight="fontWeightLight"> 
                                        Velocidad de carga hasta 800Kb.</Box>
        
                                        <Box 
                                        className={classes.parrafo}
                                        textAlign="center"
                                         fontWeight="fontWeightLight"> 
                                        Servicio técnico garantizado 24/48Hs.</Box>
                                        <Link to="/Contacto" style={{ color: 'inherit', textDecoration: 'inherit'}}>
                                            <Button className={classes.buttonContratar} variant="contained" size="large" color="primary">Contratar</Button>
                                        </Link>
                                       
                                    </div>
                                        </Typography>
                                    </div>
                                    <div className={classes.containerPlanes}>
                                <img className={classes.pruebaimg1} src="https://i.ibb.co/vLJL7MM/logo-easdmpres-Asset-17.png" alt="Planes Premium" border="0" width="150"/>
                                <Typography color="initial">
                                <div className={classes.containerTotal}>
                                    <div className="MuiIcon-fontSizeLarge"></div>
                                        <Box 
                                        className={classes.subtitle} 
                                        textAlign="center" 
                                        fontWeight="fontWeightBold" 
                                        fontSize="h2.fontSize">
                                             10 MB</Box>
        
                                        <Box 
                                        className={classes.subsubtitle}
                                         textAlign="center" 
                                         fontWeight="fontWeightLight"
                                          fontSize="h3.fontSize"
                                          letterSpacing={6}>
                                              Premium</Box>
        
                                        <div className={classes.linea}></div>
        
                                        <Box 
                                        className={classes.parrafo}
                                        textAlign="center" 
                                        fontWeight="fontWeightLight"> 
                                        Velocidad de descarga 10MB.</Box>
        
                                        <Box 
                                        className={classes.parrafo}
                                        textAlign="center" 
                                        fontWeight="fontWeightLight"> 
                                        Velocidad de carga hasta 1MB</Box>
        
                                        <Box 
                                        className={classes.parrafo}
                                        textAlign="center"
                                        fontWeight="fontWeightLight"> 
                                        Servicio técnico garantizado 24/48Hs.</Box>
                                        <Link to="/Contacto" style={{ color: 'inherit', textDecoration: 'inherit'}}>
                                            <Button className={classes.buttonContratar} variant="contained" size="large" color="primary">Contratar</Button>
                                        </Link>
                                        
                                        </div>
                                        </Typography>
                                    </div>
                        {/* fin div prueba */}
                    </div>
                    ) : null}

                    {showPymes ? (
                    <div className={classes.pruebadivplanes}>
                    {/* comienzo div prueba */}
                    <div className={classes.containerPlanes}>
                             
                         <img className={classes.pruebaimg1}  src="https://i.ibb.co/pQWdhzv/logo-empres-Asset-16.png" alt="Plan Pymes" border="0" width="150"/>
                         <Typography color="initial">
                         <div className={classes.containerTotal}> 

                                <Box 
                                className={classes.subtitle}
                                 textAlign="center"
                                  fontWeight="fontWeightBold"
                                   fontSize="h2.fontSize">
                                    +MB  </Box>

                                <Box 
                                className={classes.subsubtitle}
                                 textAlign="center" 
                                 fontWeight="fontWeightLight" 
                                 fontSize="h3.fontSize"
                                 letterSpacing={6}>
                                     Pymes</Box>

                                     <div className={classes.linea}></div>

                                <Box 
                                className={classes.parrafo}
                                textAlign="center"
                                 fontWeight="fontWeightLight"> 
                                Planes simetricos.</Box>

                                <Box 
                                className={classes.parrafo}
                                textAlign="center" 
                                fontWeight="fontWeightLight"> 
                                Planes personalizados.</Box>

                                <Box 
                                className={classes.parrafo}
                                textAlign="center"
                                 fontWeight="fontWeightLight"> 
                                Servicio técnico garantizado 24/48Hs.</Box>

                                        <Link to="/Contacto" style={{ color: 'inherit', textDecoration: 'inherit'}}>
                                            <Button className={classes.buttonContratar} variant="contained" size="large" color="primary">Contratar</Button>
                                        </Link>
                            </div>
                                </Typography>
                            </div>
                    </div>
                    ) : null}

                    {showEmpresa ? (
                    <div className={classes.pruebadivplanes}>
                        <div className={classes.containerPlanes}>
                        <img className={classes.pruebaimg1} src="https://i.ibb.co/jDY268F/empresa.png" alt="Plan Empresa" border="0" width="150"/>
                        <Typography color="initial">
                        <div className={classes.containerTotal}>
                            <div className="MuiIcon-fontSizeLarge"></div>
                                <Box 
                                className={classes.subtitle} 
                                textAlign="center" 
                                fontWeight="fontWeightBold" 
                                fontSize="h2.fontSize">
                                     +MB</Box>

                                <Box 
                                className={classes.subsubtitle}
                                 textAlign="center" 
                                 fontWeight="fontWeightLight"
                                  fontSize="h3.fontSize"
                                  letterSpacing={6}>
                                      Empresas</Box>

                                <div className={classes.linea}></div>

                                <Box 
                                className={classes.parrafo}
                                textAlign="center" 
                                fontWeight="fontWeightLight"> 
                                Planes simetricos.</Box>

                                <Box 
                                className={classes.parrafo}
                                textAlign="center" 
                                fontWeight="fontWeightLight"> 
                               Planes personalizados.</Box>

                                <Box 
                                className={classes.parrafo}
                                textAlign="center"
                                fontWeight="fontWeightLight"> 
                                Servicio técnico garantizado 24/48Hs.</Box>
                                <Link to="/Contacto" style={{ color: 'inherit', textDecoration: 'inherit'}}>
                                    <Button className={classes.buttonContratar} variant="contained" size="large" color="primary">Contratar</Button>
                                </Link>
                                </div>
                                </Typography>
                            </div>
                            </div>
                    ) : null}


                    
                    <div className={classes.alert} ref={container} />
            </div>
        </div>
    );
};

export default Planes;