import React, {useState} from 'react';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import emailjs from 'emailjs-com';
//----------------------------------------------------------------------------------//
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
//----------------------------------------------------------------------------------//
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import NumberFormat from 'react-number-format';

const useStyles = makeStyles((theme) => ({
        contenedor: {
        width: "50%",
        height: "700px",
        display: "flex",
        background: "#2222",
        flexFlow: "column wrap",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "3em",
        
        [theme.breakpoints.between("651","930")]: {
            fontSize: "20px",
            display: "flex",
            height: "auto",
        }, 

        [theme.breakpoints.between("401","650")]: {
          fontSize: "20px",
          display: "flex",
          height: "auto",
          width: "60%",
          marginLeft: "auto",
        marginRight: "auto",
      }, 
      [theme.breakpoints.between("321","400")]: {
        fontSize: "20px",
        display: "flex",
        height: "auto",
        width: "60%",
        marginLeft: "0px",
        marginRight: "0px",
    },   
      
      [theme.breakpoints.between("0","320")]: {
        fontSize: "20px",
        display: "flex",
        height: "auto",
        width: "60%",
        marginLeft: "0px",
        marginRight: "0px",
    },   
      
    },
    divprincipal: {
        backgroundImage: `url("https://i.ibb.co/vJWFNF6/img4.jpg")`,
        backgroundSize: 'cover',
            overflow: 'hidden',
        width:'100%', 
        height: "840px",
        display: "flex",
        flexFlow: "row wrap",
        justifyContent: "center",
        [theme.breakpoints.between("0","930")]: {
            display: "flex",
            flexFlow: "column wrap", 
            height: "900px",
        },
    },
    
    TextField: {
        backgroundColor: "white",
        marginBottom: "0.5em",
        marginTop: "0.5em",
        borderRadius: "0.5em",
        width: "30em",
        

        [theme.breakpoints.between("590","930")]: {
            display: "flex",
            flexFlow: "column wrap", 
            width: "20em",

        },
        [theme.breakpoints.between("330","590")]: {
          width: "15em",
            display: "flex",
            flexFlow: "column wrap", 
        },
        [theme.breakpoints.between("0","330")]: {
          width: "12em",
            display: "flex",
            flexFlow: "column wrap", 
        },
        
    },
    divTextoFooter: {
      
        width: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.between("0","930")]: {
            display: "flex",
            flexFlow: "column wrap", 
            width: "100%",
            
            
        },
            },
    subtitleContact: {
    color: "white",
    [theme.breakpoints.between("590","930")]: {
        fontSize: "17px",
        display: "flex",
        marginBottom: "0.2em",
        marginTop: "0.2em",
        alignItems: "center",
    },    
    [theme.breakpoints.between("0","590")]: {
        fontSize: "15px",
        display: "flex",
        marginBottom: "0.2em",
        marginTop: "0.2em",
    },      
    },
    titleContact: {
        color: "white",
        [theme.breakpoints.between("590","930")]: {
            fontSize: "40px",
            display: "flex",
            marginTop: "1em",
            marginBottom: "0.1em",
            
        },    
        [theme.breakpoints.between("0","590")]: {
            fontSize: "30px",
            display: "flex",
            marginTop: "1em",
            marginBottom: "0.1em",
        },     
        },
    buttonForm: {
        marginBottom: "0.5em",
        marginTop: "0.5em",
        borderRadius: "0.5em",
        [theme.breakpoints.between("0","560")]: {
          width: "300px",
          height: "40px",
       },  
        [theme.breakpoints.between("561","930")]: {
         width: "300px",
         height: "40px",
      },  
      [theme.breakpoints.between("0","330")]: {
        width: "16em",
          display: "flex",
          flexFlow: "column wrap", 
      },
        }
    }));

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
      }

      function TextMaskCustom(props) {
        const { inputRef, ...other } = props;
      
        return (
          <MaskedInput
            {...other}
            ref={(ref) => {
              inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/[1-9]/,/\d/, /\d/,/\d/, /\d/, /\d/,/\d/, /\d/, /\d/, /\d/,/\d/,/\d/,/\d/]}
            
            showMask
          />
        );
      };

      TextMaskCustom.propTypes = {
        inputRef: PropTypes.func.isRequired,
      };
      
      function NumberFormatCustom(props) {
        const { inputRef, onChange, ...other } = props;
      
        return (
          <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
              onChange({
                target: {
                  name: props.name,
                  value: values.value,
                },
              });
            }}
            
            isNumericString
            
          />
        );
      }
      
      NumberFormatCustom.propTypes = {
        inputRef: PropTypes.func.isRequired,
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
      };

const Contacto = () => {
    const classes = useStyles();

//validacion tel
const [values, setValues] = React.useState({
 
});

const handleChange = (event) => {
  setValues({
    ...values,
    [event.target.name]: event.target.value,
  });
};
    
//SNACKBAR
const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

//emailJS

const enviarEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_6rbpu6r', 'template_ur4oy3r', e.target, 'user_tGVwQDjJApa7BuBDDPBlz')
      .then((result) => {
        
      }, (error) => {
        
      });
      e.target.reset()
  }
  

//FIN EmailJS
    
    return (
        <div className={classes.divprincipal} id="/contacto">
            <div className={classes.divTextoFooter}>
            <Typography variant="h1" color="initial">
                <Box textAlign="center" fontWeight="fontWeightBold" fontSize="h3.fontSize" className={classes.titleContact}>
                    Contáctanos
                </Box>
                <Box textAlign="center" fontWeight="fontWeightLight" fontSize="h5.fontSize" className={classes.subtitleContact}>
                  <LocationOnIcon/> Ubicación: Mitre Este 77, Metán, Salta
                </Box>
                <Box textAlign="center" fontWeight="fontWeightLight" fontSize="h5.fontSize" className={classes.subtitleContact}>
                    <PhoneIcon/> Télefono: 3876-454496
                </Box>
                <Box textAlign="center" fontWeight="fontWeightLight" fontSize="h5.fontSize" className={classes.subtitleContact}>
                   <EmailIcon/> Correo electrónico: admin@nextinternet.com.ar
                </Box>
            </Typography>
            </div>
            
            <Container className={classes.contenedor}>
                <form onSubmit={enviarEmail}>
                <Grid container spacing={1}>
                    <Grid md="12" >
                        <TextField 
                        id="nombre" 
                        label="Nombre" 
                        variant="filled" 
                        type="text"
                        required={true}
                        className={classes.TextField}
                        name="nombre"
                        
                        />
                    </Grid>
                    <Grid md="12">
                        <TextField 
                        id="email" 
                        label="Correo Electrónico" 
                        variant="filled" 
                        type="email"
                        required={true}
                        className={classes.TextField}
                        name="email"
                        
                        />
                    </Grid>
                    <Grid md="12">
                    <TextField 
                        id="telefono" 
                        label="Teléfono" 
                        variant="filled"
                        type="text"
                        value={values.numberformat}
                        // value={values.textmask}
                        onChange={handleChange}
                        required={true}
                        className={classes.TextField}
                        // inputComponent={TextMaskCustom}
                        name="telefono"
                        inputComponent={TextMaskCustom}
                        
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                        />
                    </Grid>
                    
                    <Grid md="12" >
                    <TextField 
                    className={classes.formulario}
                        id="asunto"
                        label="Asunto" 
                        variant="filled"
                        type="text"
                        required={true}
                        multiline={true}
                        rows={6}
                        className={classes.TextField}
                        name="asunto"
                        />
                    </Grid>
                    <Button 
                    className={classes.buttonForm} 
                    variant="contained" 
                    type="submit" 
                    size= "large" 
                    onClick={handleClick}>
                         Enviar 
                         </Button>
                         
                </Grid>
                </form>
                <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
        <Alert 
        onClose={handleClose} 
        severity="success">
          ¡Tu mensaje fue enviado con éxito!
        </Alert>
      </Snackbar>



            </Container>
        </div>
    );
    
};
  

export default Contacto;