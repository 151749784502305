import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import emailjs from 'emailjs-com';
//----------------------------------------------------------------------------------//
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
//----------------------------------------------------------------------------------//
import '../styleContactoPersona.css';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
//----------------------------------------------------------------------------------//
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import NumberFormat from 'react-number-format';
//----------------------------------------------------------------------------------//




const useStyles = makeStyles((theme) => ({

divprincipal: {
    // backgroundImage: `url("https://i.ibb.co/FJ4FSqn/form.jpg")`,
    backgroundColor: "#ebebeb",
    width: "100%",
    height: "970px",
},
contenedor: {
    width: "40%",
    height: "100%",
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "2em",
    
    [theme.breakpoints.between("0","930")]: {
        display: "flex",
        flexFlow: "row wrap",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
    },    
    
},

TextField: {
    backgroundColor: "#D7D7D7",
    margin: "1em",
    width: "25em",
    [theme.breakpoints.down("381")]: {
      width: "20em",
    }, 
    [theme.breakpoints.down("310")]: {
      width: "18em",
    },
},
TextFieldAsunto:{
  margin: "1em",
  width: "25em", 
  [theme.breakpoints.down("381")]: {
    width: "20em",
  },
  [theme.breakpoints.down("310")]: {
    width: "18em",
  },
  
},

RadioGroupButton: {
    color: "black",
    width: "90%",
    paddingLeft: "3em",
    
    [theme.breakpoints.down("381")]: {
      width: "90%",
      paddingLeft: "1em",
    
    },
    [theme.breakpoints.down("310")]: {
      width: "90%",
    },
},
RadioGroupButtonGrid: {

marginRight: "auto",
marginLeft: "auto",
paddingLeft: "10em",
[theme.breakpoints.down("1920")]: {
  width: "90%",
  
  paddingLeft: "9em",
},

[theme.breakpoints.down("381")]: {
  width: "90%",
  
 
},
[theme.breakpoints.between("0","930")]: {
  width: "90%",
  paddingLeft: "6em",
    
},

[theme.breakpoints.down("1394")]: {
    width: "100%",
    paddingLeft: "7em",
    
    
}, 
[theme.breakpoints.up("1679")]: {
    width: "100%",
    marginTop: "1em",
    marginLeft: "3.5em",
    marginRight: "4.5em",
    
}, 
},


textoRadio:{
color: "black",
width: "21.em",
fontWeight: "bold",
 
},
radiocontrol:{
  
},

buttonForm: {
            marginBottom: "0.5em",
            marginTop: "1em",
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: "0.5em",
            width: "20em",
            background: "black",
            color: "white",

        [theme.breakpoints.up("1394")]: {
            marginBottom: "0.5em",
            marginTop: "1em",
            marginLeft: "auto",
            marginRight: "auto",
        },    

},
prueba: {
margin: "0px",
padding: "0px",
marginTop: "3em",


},

}));



function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };


//TELEFONO
function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      
      
      showMask
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      
      isNumericString
      
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
  // function TextMaskCustom(props) {
  //   const { inputRef, ...other } = props;
  
  //   return (
  //     <MaskedInput
  //       {...other}
  //       ref={(ref) => {
  //         inputRef(ref ? ref.inputElement : null);
  //       }}
  //       mask={[/[1-9]/,/\d/, /\d/,/\d/, /\d/, /\d/,/\d/, /\d/, /\d/, /\d/,/\d/,/\d/,/\d/]}
  //       placeholderChar={'\u2000'}
  //       showMask
  //     />
  //   );
  // }
  
  // TextMaskCustom.propTypes = {
  //   inputRef: PropTypes.func.isRequired,
  // };
  
  // function NumberFormatCustom(props) {
  //   const { inputRef, onChange, ...other } = props;
  
  //   return (
  //     <NumberFormat
  //       {...other}
  //       getInputRef={inputRef}
  //       onValueChange={(values) => {
  //         onChange({
  //           target: {
  //             name: props.name,
  //             value: values.value,
  //           },
  //         });
  //       }}
  //       thousandSeparator
  //       isNumericString
  //       prefix="$"
  //     />
  //   );
  // }
  
  // NumberFormatCustom.propTypes = {
  //   inputRef: PropTypes.func.isRequired,
  //   name: PropTypes.string.isRequired,
  //   onChange: PropTypes.func.isRequired,
  // };

//---------------------------------------------------------------COMIENZA--------------------------------------------------------------//
  


const ContactoPlanes = () => {
const classes = useStyles();



    const [value, setValue] = React.useState('Metán, Salta');

    const handleChangeRadioButton = (event) => {
      console.warn(event.target.value)
        setValue(event.target.value);
    };


//validation
const [values, setValues] = useState("");
//TELEFONO CHANGE
// const handleChange = (event) => {
//   setValues({
//     ...values,
//     [event.target.name]: event.target.value,
//   });
// };
const handleChange = (event) => {
  setValues({
    ...values,
    [event.target.name]: event.target.value,
  });
};

//SNACKBAR
const [open, setOpen] = React.useState(false);

const handleClick = () => {
setOpen(true);
};

const handleClose = (event, reason) => {
if (reason === 'clickaway') {
  return;
}

setOpen(false);
};

//emailJS

const enviarEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_6rbpu6r', 'template_cdx6cge', e.target, 'user_tGVwQDjJApa7BuBDDPBlz')
      .then((result) => {
        
      }, (error) => {
        
      });
      e.preventDefault();
      e.target.reset();
  };
//FIN EmailJS


    return (
        <div className={classes.divprincipal}>
            

            <Container className={classes.contenedor}>
                <form className={classes.prueba} onSubmit={enviarEmail} >
                <Grid 
                        container 
                        className={classes.prueba}
                        direction="row"
                        justify="center"
                        alignItems="center">

                    <Grid item 
                          >
                           

                        <TextField 
                        
                        id="nombrePlan" 
                        label="Nombre" 
                        variant="filled" 
                        type="text"
                        required={true}
                        className={classes.TextField}
                        name="nombrePlan"
                        //prueba
                        
                        />
                    </Grid>
                    <Grid item >
                        <TextField 
                        id="apellidoPlan" 
                        label="Apellido" 
                        variant="filled" 
                        type="text"
                        required={true}
                        className={classes.TextField}
                        name="ApellidoPlan"
                        
                        />
                    </Grid>
                    <Grid item >
                        <TextField 
                        id="emailPlan" 
                        label="Correo Electronico" 
                        variant="filled"
                        type="email"
                        required={true}
                        className={classes.TextField}
                        name="emailPlan"
                        />
                    </Grid>
                    <Grid item >
                        <TextField 
                        id="telefonoPlan" 
                        label="Teléfono" 
                        variant="filled"
                        type="text"
                        value={values.numberformat}
                        // value={values.textmask}
                        onChange={handleChange}
                        required={true}
                        className={classes.TextField}
                        inputComponent={TextMaskCustom}
                        name="telefonoPlan"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                        />
                    </Grid>
                    <Grid item >
                    <TextField
                        id="asuntoPlan"
                        label="Asunto" 
                        variant="filled"
                        type="text"
                        required={true}
                        multiline={true}
                        rows={6}
                        className={classes.TextFieldAsunto}
                        name="asuntoPlan"
                        />
                    </Grid>
                    
                    <Grid item className={classes.RadioGroupButtonGrid}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend" className={classes.textoRadio} required={true}>Localidad</FormLabel>
                        <RadioGroup
                            aria-label="Localidad"
                            name="localidadPlan"
                            value={value}
                            className={classes.RadioGroupButton}
                            >
                        <FormControlLabel 
                            value="Metán, Salta" 
                           
                            control={<Radio />} 
                            checked={value==="Metán, Salta" }
                            label="Metán, Salta" 
                            className={classes.radiocontrol}
                            onChange={handleChangeRadioButton}
                            >
                        </FormControlLabel>
                        <FormControlLabel
                            value="Metán Viejo, Salta" 
                           
                            control={<Radio />} 
                            checked={value==="Metán Viejo, Salta" }
                            label="Metán Viejo, Salta" 
                            className={classes.radiocontrol}
                            onChange={handleChangeRadioButton}
                           >
                        </FormControlLabel>
                        <FormControlLabel
                            value="Otros" 
                           
                            control={<Radio />} 
                            checked={value==="Otros" }
                            label="Otros" 
                            className={classes.radiocontrol}
                            onChange={handleChangeRadioButton}
                           >
                        </FormControlLabel>
                     </RadioGroup>
                     </FormControl>
                    </Grid>

                    



                    <Button 
                    className={classes.buttonForm} 
                    variant="contained" 
                    type="submit" 
                    size= "large" 
                    onClick={handleClick}>
                         Enviar 
                         </Button>
                         
                </Grid>
                </form>
                <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
        <Alert 
        onClose={handleClose} 
        severity="success">
          ¡Tu mensaje fue enviado con éxito!
        </Alert>
      </Snackbar>



            </Container>





        </div>
    );
};

export default ContactoPlanes;