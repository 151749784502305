import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
//----------------------------------------------------------------//
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';

import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
//----------------------------------------------//
import '../navbar.css';
//-----------------------------------------------------------//
import {
  Link
} from "react-router-dom";


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
width: "100%",
  },
    //CODIGO ANTERIOR
    logoNext: {
        height: 53,
        width: 140 ,
        marginLeft: "10em",
        marginTop: "1em",
        marginBottom : "1em",
        [theme.breakpoints.between("357", "505")]: {
          height:45,
          width: 120 ,
          marginLeft: "1em",
          marginTop: "2em",
          marginBottom : "1em",
      },
        [theme.breakpoints.between("505", "1251")]: {
          height: 53,
        width: 140 ,
        marginLeft: "4em",
        marginTop: "1.5em",
        marginBottom : "1em",
      },
      [theme.breakpoints.up(1251)]: {
        height: 53,
      width: 140 ,
      marginLeft: "4em",
      marginTop: "1.5em",
      marginBottom : "1em",
    },
      [theme.breakpoints.between("314", "357")]: {
        height:45,
        width: 120 ,
        marginLeft: "1em",
        marginTop: "2em",
        marginBottom : "1em",
    },
    [theme.breakpoints.between("293", "314")]: {
      height:45,
    width: 120 ,
    marginLeft: "1em",
    marginTop: "2em",
    marginBottom : "1em",
  },
  [theme.breakpoints.between("0", "293")]: {
    height:35,
  width: 80 ,
  marginLeft: "0.5em",
  marginTop: "2.5em",
  marginBottom : "1em",
},
    },
      menuButton: {
        marginRight: theme.spacing(2),
        borderRadius: 10,
        marginTop: "1.5em",
        marginBottom : "1em",
        color: "white",
        '&:hover': {
          background: "#006C6C",
        },
      },
      
  }));

const Back = () => {
    const classes = useStyles();
  const theme = useTheme();
const [navbar,setNavbar] = useState(false);


  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        // style={{backgroundColor: "#121132"}}
        elevation={0}
        // className={navbar ? 'navbar active' : 'navbar' }
        className="navbarBack"
      >
          <Link to="/"><img src="https://i.ibb.co/q9hZYYN/logochico.png" alt="Next Internet" className={classes.logoNext}/></Link>
        <Toolbar>
          
            
        <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            className={classes.menu}
            
             >
            <Link to="/"
            style={{textDecoration: "none"}}>
                <Button className={classes.menuButton} 
                color="inherit" 
                >
                  <ArrowBackIcon/> Volver
                  </Button>
            </Link>
              </Grid>
           
          
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Back;