import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    createMuiTheme,
    responsiveFontSizes,
    MuiThemeProvider,
    Typography
} from '@material-ui/core';

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);
const title = "SERVICIOS"

const text = "Desde NEXT, superamos los desafíos con la incorporación de lo ultimo en tecnología. Nuestra versatilidad tecnológica nos hace superiores damos soluciones tomando de base la experiencia. Maximizar la velocidad y minimizar los costos, son nuestros pilares para lograr el mejor resultado."

const useStyles = makeStyles((theme) => ({
    divGeneral: {
        width: "90%",
        height: "100vh",
        display: "flex",
        flexFlow: "row wrap",
        marginLeft: "auto",
        marginRight: "auto",
       marginBottom: "2em",
       marginTop: "2em",

       [theme.breakpoints.between("0", "600")]: {
        width: "90%",
        height: "80vh",
        display: "flex",
        flexFlow: "row wrap",
        marginLeft: "auto",
        marginRight: "auto",
       marginBottom: "2em",
       marginTop: "2em",
       },
    },
    divContainer: {
        width: "100%",
        display: "flex",
        flexFlow: "row wrap",
        justifyContent: "center",
        
        
    },
    divContainerTexto: {
        width: "49%",
        display: "flex",
        flexFlow: "column wrap",
        justifyContent: "center",
        [theme.breakpoints.between("0", "700")]: {
            width: "100%",
            
        },
    },
    divContainerImg: {
        width: "51%",
        display: "flex",
        marginLeft: "auto",
        marginRight: "auto",
      
        [theme.breakpoints.between("0", "1075")]: {
            width: "100%",
            display: "flex",
            
            marginTop: "0px",
            justifyContent: "center",
            order: 2,
            alignSelf: "flex-start",
        },
    },
    Img: {
        width: "60%",
        display: "flex",
        
        maxWidth: "60%",
        minWidth: "20%",
        height: "41.5%",
        marginRight: "auto",
        margin: "auto",
        marginLeft: "0px",
        
        // [theme.breakpoints.between("0", "1074")]: {
        //     maxWidth: "80%",
       
        // marginLeft: "auto",
        // marginRight: "auto",
        
        // },
        [theme.breakpoints.between("0", "490")]: {
            width: "500px",
            marginTop: "0px",
            marginLeft: "auto",
            marginRight: "auto",
        },
       
        [theme.breakpoints.between("491", "900")]: {
            width: "400px",
            marginRight: "auto",
            marginLeft: "auto",
            paddingTop: "0px",
        },
        [theme.breakpoints.between("900", "1300")]: {
            width: "500px",
            marginRight: "auto",
            marginLeft: "auto",
            
            
        },
    },
    
    title: {
        color: "#121132",
        fontWeight: "bold",
        
    },

   
    text: {
        color: "#121132",
        fontWeight: "light",
        letterSpacing: 2,
       
        
    },

    linea: {
        borderBottom: "2px solid #3DEBE8",
        alignSelf: "center",
        marginTop: "0.5em",
        marginBottom: "0.5em",
        // marginBottom: "1em",
        // marginLeft: "auto",
        // marginRight: "auto",
        [theme.breakpoints.between("0", "549")]: {
            width:"80%",
        borderBottom: "2px solid #3DEBE8",
        alignSelf: "center",
        // marginTop: "0.5em",
        // marginBottom: "0.5em",
        // marginLeft: "auto",
        // marginRight: "auto",
        },
    
        [theme.breakpoints.between("550", "763")]: {
            width:"250px",
        borderBottom: "2px solid #3DEBE8",
        alignSelf: "center",
        // marginTop: "0.5em",
        // marginBottom: "0.5em",
        // marginLeft: "auto",
        // marginRight: "auto",
        },
        [theme.breakpoints.between("764", "960")]: {
            width:"300px",
        borderBottom: "2px solid #3DEBE8",
        alignSelf: "center",
        },

        [theme.breakpoints.between("961", "1016")]: {
            width:"200px",
        borderBottom: "2px solid #3DEBE8",
        alignSelf: "center",
        },
        [theme.breakpoints.up(1016)]: {
            width:"450px",
        borderBottom: "2px solid #3DEBE8",
        alignSelf: "center",
        },
    },
}));

const Servicios = () => {
    const classes = useStyles();
    return (
        <div className={classes.divGeneral} id="/servicios">
            <div className={classes.divContainer}>

            <div className={classes.divContainerImg} >
                    <img className={classes.Img} src="https://i.ibb.co/s6xYFj8/15x15logo-empres-Asset-16.png" alt="Con Next mirá todo en alta definición"/>
                </div>

                <div className={classes.divContainerTexto}>
                
                    <MuiThemeProvider theme={theme}>
                        <Typography variant="h3" gutterBotton className={classes.title} align="center">{title} </Typography>
                
                        

                        <Typography variant="h4" gutterBotton className={classes.linea} align="center"> </Typography>

                        <Typography variant="subtitle1"  gutterBotton className={classes.text} align="center">{text}</Typography>

                    </MuiThemeProvider>
                </div>

                
            </div>
        </div>
    );
};

export default Servicios;