import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    createMuiTheme,
    responsiveFontSizes,
    MuiThemeProvider,
    Typography
} from '@material-ui/core';

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);
const title = "LO MEJOR PARA ESTAR CONECTADO"
const subtitle = "Nuestros planes están diseñados especialmente para vos, con la velocidad que necesitas para disfrutar o trabajar desde tu casa."
// const subtitle1 = "Contando con el soporte preferencial y personalizado que necesitás para trabajar o disfrutar mejor desde tu casa."


const useStyles = makeStyles((theme) => ({
    divGeneral: {
        width: "90%",
        display: "flex",
        flexFlow: "row wrap",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "2em",
        
    },
    divContainer: {
        width: "100%",
        display: "flex",
        flexFlow: "row wrap",
        justifyContent: "center",
        
        
    },
    divContainerTexto: {
        width: "49%",
        display: "flex",
        flexFlow: "column wrap",
        justifyContent: "center",
        [theme.breakpoints.between("0", "700")]: {
            width: "100%",
            justifyContent: "center",
            paddingBottom: "0px"
            
        },
    },
    divContainerImg: {
        width: "51%",
        display: "flex",
        marginLeft: "auto",
        marginRight: "auto",
      
        [theme.breakpoints.between("0", "1075")]: {
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignSelf: "center",
            order: 2,
        },
    },
    Img: {
        // display: "flex",
        // maxWidth: "100%",
        //  marginRight: "auto",
        // [theme.breakpoints.between("0", "1074")]: {
        // },
        display: "flex",
        marginRight: "auto",
        
        minWidth: "20%",
        maxWidth: "70%",
       
       
        // display: "flex",
        // alignSelf: "center",
        // [theme.breakpoints.between("901", "1074")]: {
            
        //     marginRight: "auto",
        //     marginLeft: "auto",
        // },
        // [theme.breakpoints.between("0", "900")]: {
        //     marginRight: "auto",
        //     marginLeft: "auto",
        // },
        [theme.breakpoints.between("0", "492")]: {
            width: "500px",
            marginLeft: "auto",
            marginRight: "auto",
        },
        [theme.breakpoints.between("492", "900")]: {
            width: "700px",
            marginRight: "auto",
            marginLeft: "auto",
        },
        
        [theme.breakpoints.between("900", "1300")]: {
            width: "500px",
            marginRight: "auto",
            marginLeft: "auto",
            
        },
    },
    
    title: {
        color: "#121132",
        fontWeight: "bold",
    },

    subtitle: {
        paddingTop: "0.5em",
        color: "#121132",
        fontWeight: "light",
        letterSpacing: 2,
        
          
    },

    linea: {
    
        borderBottom: "2px solid #3DEBE8",
        alignSelf: "center",
        marginTop: "0.5em",
        marginBottom: "0.5em",
        // marginBottom: "1em",
        // marginLeft: "auto",
        // marginRight: "auto",
        [theme.breakpoints.between("0", "549")]: {
            width:"80%",
        borderBottom: "2px solid #3DEBE8",
        alignSelf: "center",
        // marginTop: "0.5em",
        // marginBottom: "0.5em",
        // marginLeft: "auto",
        // marginRight: "auto",
        },
    
        [theme.breakpoints.between("550", "763")]: {
            width:"250px",
        borderBottom: "2px solid #3DEBE8",
        alignSelf: "center",
        // marginTop: "0.5em",
        // marginBottom: "0.5em",
        // marginLeft: "auto",
        // marginRight: "auto",
        },
        [theme.breakpoints.between("764", "960")]: {
            width:"300px",
        borderBottom: "2px solid #3DEBE8",
        alignSelf: "center",
        },

        [theme.breakpoints.between("961", "1016")]: {
            width:"200px",
        borderBottom: "2px solid #3DEBE8",
        alignSelf: "center",
        },
        [theme.breakpoints.up(1016)]: {
            width:"450px",
        borderBottom: "2px solid #3DEBE8",
        alignSelf: "center",
        },
    },
}));

const Section = () => {
    const classes = useStyles();
    return (
        <div className={classes.divGeneral} >
            <div className={classes.divContainer}>

            <div className={classes.divContainerImg} >
                    <img  className={classes.Img} id="empresasid" src="https://i.ibb.co/8rDJDnN/applemonitor.png" alt="Con Next mirá todo en alta definición"/>
                </div>

                <div className={classes.divContainerTexto}>
                
                    <MuiThemeProvider theme={theme}>
                        <Typography variant="h4" gutterBotton className={classes.title} align="center">{title} </Typography>
                
                        <Typography variant="h4" gutterBotton className={classes.linea} align="center"> </Typography>

                        <Typography variant="subtitle1" gutterBotton className={classes.subtitle} align="center">{subtitle}</Typography>
                        

                    </MuiThemeProvider>

                </div>
            </div>
        </div>
    );
};

export default Section;