import React from 'react';
import Header from './components/Header';
import Back from './components/Back';
import Carousel from './components/Carousel'
import Planes from './components/Planes'

import Servicios from './components/Servicios'
import Section from './components/Section'
import Section2 from './components/Section2'
import Contacto from './components/Contacto'
import ContactoPlanes from './components/ContactoPlanes'
import Logros from './components/Logros'
//-----------------------------------------------------//



//-----------------------------------------------------//
import {
  BrowserRouter as Router,
  // HashRouter as RouterHash,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";

function App() {
  return (
    <Router>
     
    <div className="App">  
    
      
    
        <Switch>
          <Route path="/Contacto" >
              <Back/>
              <ContactoPlanes/>
          </Route>
        </Switch>
    
        <Switch>
         <Route path="/" exact> 
              <Header/>
              <Carousel/>
              <Planes/>
              <Section/>
              <Section2/>
              <Logros/>
              <Servicios/>
              <Contacto/>
              
         </Route>
          
        </Switch>


    




        
      
      
      
    </div>
   
    </Router>
  );
}

export default App;
